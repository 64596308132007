@use "sass:map";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400%3B500%3B600&display=swap');

$font-family: 'Inter';
$font-sizes: (
    links: 16px,
    small-links: 12px,
    text: 14px,
    small-text: 12px,
    large-text: 16px,
    header1: 20px,
    header2: 18px,
    header3: 16px,
    header4: 14px,
    header5: 12px
);
$line-heights: (
    links: 22px,
    small-links: 12px,
    text: 22px,
    small-text: 16px,
    large-text: 26px,
    header1: 24px,
    header2: 22px,
    header3: 20px,
    header4: 20px,
    header5: 18px
);

@mixin font($category)
{
    font-weight: 400;
    font-size: map.get($font-sizes, $category);
    font-family: $font-family;
    font-style: normal;
    line-height: map.get($line-heights, $category);
}

@mixin header($category)
{
    @include font($category);
    font-weight: 600;
}

@mixin inv-typography()
{
    h1 { @include header(header1); }
    h2 { @include header(header2); }
    h3 { @include header(header3); }
    h4 { @include header(header4); }
    h5 { @include header(header5); }
}