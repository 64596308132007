:root, [theme="default"]
{
    --font: TheSaB5,'Trebuchet MS',Arial,Helvetica,sans-serif;
    --primary-bg-color: white;
    --accent-bg-color: #eeeeee;
    --primary-fg-color: black;
    --element-bg-color: white;
    --base-contrast-rgb: 0, 0, 0;
    --control-bg-color: rgba(var(--base-contrast-rgb), 0.05);
    --control-border-color: rgba(var(--base-contrast-rgb), 0.3);

    --tracker-option-bg-color: #00ff0011;

    --icon-button-fg-color: #666666;
    --icon-button-fg-hover-color: #333333;
    --icon-button-fg-active-color: #111111;
    
    --map-base-contrast-rgb: 0, 0, 0;
    --map-control-shadow-color: rgba(0, 0, 0, 0.3);
    --map-control-shadow: var(--map-control-shadow-color) 0px 1px 4px -1px;
    --map-control-bg-color: var(--primary-bg-color);
    --map-control-fg-color: var(--icon-button-fg-color);
    --map-control-fg-hover-color: var(--icon-button-fg-hover-color);
    --map-control-fg-active-color: var(--map-control-fg-active-color);
}

.mat-tooltip.tooltip-bigger
{
    font-size: 14px;
}